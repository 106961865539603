<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} Endpoint</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-4">
                    <ValidationProvider
                      name="Endpoint Name"
                      :rules="'required|alpha_dash'"
                      v-slot="{ errors, classes }"
                    >
                      <label>Endpoint Name *</label>
                      <input
                        type="text"
                        placeholder="Name"
                        :readonly="isUpdate"
                        v-model="apiEndpoint.name"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-4">
                    <label>URL (http://IP:Port) *</label>
                    <ValidationProvider
                      name="URL"
                      :rules="{ required: true, url }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="IP:PORT"
                        v-model="apiEndpoint.url"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-4">
                    <ValidationProvider
                      name="Config Key"
                      :rules="'required|alpha_num'"
                      v-slot="{ errors, classes }"
                    >
                      <label>Config Key *</label>
                      <input
                        type="text"
                        placeholder="Config Key"
                        v-model="apiEndpoint.configKey"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-2">
                    <label>Response Timeout *</label>
                    <ValidationProvider
                      name="Response Timeout"
                      :rules="'required|integer'"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="number"
                        placeholder="Response Timeout"
                        v-model="apiEndpoint.httpTimeout"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-2">
                    <label>HTTP Timeout *</label>
                    <ValidationProvider
                      name="Http Timeout"
                      :rules="'required|integer'"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="number"
                        placeholder="Http Timeout"
                        v-model="apiEndpoint.responseTimeout"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-2">
                    <label>URL Version Remove *</label>
                    <div class="switch">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          class="onoffswitch-checkbox form-control"
                          v-model="apiEndpoint.urlVersion"
                          id="urlVersion"
                        />
                        <label class="onoffswitch-label" for="urlVersion">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-2">
                    <label>URL API Provider Remove *</label>
                    <div class="switch">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          class="onoffswitch-checkbox form-control"
                          v-model="apiEndpoint.apiProvider"
                          id="apiProvider"
                        />
                        <label class="onoffswitch-label" for="apiProvider">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-2" style="display: none">
                    <label>URL Customer Remove *</label>
                    <div class="switch">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          class="onoffswitch-checkbox form-control"
                          v-model="apiEndpoint.customerUrl"
                          id="customerUrl"
                        />
                        <label class="onoffswitch-label" for="customerUrl">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">Clear</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      apiEndpoint: {
        name: "",
        url: "",
        responseTimeout: 60000,
        httpTimeout: 10000,
        urlVersion: false,
        customerUrl: false,
        apiProvider: false,
        configKey: "",
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.ENDPOINT_ADD,
      isUpdate: false,
    };
  },
  created: function () {
    this.loadEndpointData();
  },
  mounted: function () {
    const self = this;
    $("#urlVersion").change(function () {
      self.apiEndpoint.urlVersion = $(this).is(":checked");
    });
    $("#apiProvider").change(function () {
      self.apiEndpoint.apiProvider = $(this).is(":checked");
    });
    $("#customerUrl").change(function () {
      self.apiEndpoint.customerUrl = $(this).is(":checked");
    });
  },
  methods: {
    onSubmit: function () {
      const self = this;
      AxiosService.post(this.url, this.apiEndpoint).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.API_ENDPOINT_ACTIVITY,
              this.title,
              AuditListner.apiEndpointAuditTemplate(self.apiEndpoint)
            )
          );
          self.$router.replace(Constant.url.SEPARATER + Constant.url.ENDPOINT_URL);
        }
      });
    },
    loadEndpointData: function () {
      const self = this;
      var endpointId = this.$route.query.id;
      if (endpointId != undefined) {
        AxiosService.get(Constant.url.ENDPOINT_GET + endpointId).then((result) => {
          self.apiEndpoint = result;
        });
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.ENDPOINT_UPDATE;
        this.isUpdate = true;
      }
    },
  },
};
</script>
